#client-logo.owl-theme .owl-controls {
  margin-top: 20px;
}

#owl-demo1.owl-theme .owl-controls .owl-page span {
  background: #2aa9b9 !important;
}

.owl-theme .owl-controls {
  text-align: center;
}

.owl-theme .owl-controls .owl-buttons div {
  color: #fff;
  zoom: 1;
  filter: Alpha(Opacity= 50);
  opacity: .8;
  background: #869791;
  border-radius: 30px;
  margin: 5px;
  padding: 3px 10px;
  font-size: 12px;
  display: inline-block;
}

.owl-theme .owl-controls.clickable .owl-buttons div:hover {
  filter: Alpha(Opacity= 100);
  opacity: 1;
  text-decoration: none;
}

.owl-theme .owl-controls .owl-page {
  zoom: 1;
  display: inline-block;
}

.owl-theme .owl-controls .owl-page span {
  filter: Alpha(Opacity= 50);
  opacity: .5;
  background: #fff;
  border-radius: 20px;
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  display: block;
}

.owl-theme .owl-controls .owl-page.active span, .owl-theme .owl-controls.clickable .owl-page:hover span {
  filter: Alpha(Opacity= 100);
  opacity: 1;
}

.owl-theme .owl-controls .owl-page span.owl-numbers {
  color: #fff;
  border-radius: 30px;
  width: auto;
  height: auto;
  padding: 2px 10px;
  font-size: 12px;
}

.owl-item.loading {
  background: url("ajax-loader.6b56de7a.gif") center no-repeat;
  min-height: 150px;
}
/*# sourceMappingURL=index.da4294be.css.map */
